import styled from 'styled-components';

export const ColorBannerContainer = styled.div<{ color: string }>`
  width: 100%;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;

  ${(props) => `
        background-color: ${props.color};
    `}
`;

export const ColorBannerSkeleton = styled.div`
  background-color: #eaeaea;
  height: 90px;
  margin-bottom: 40px;
`;

export const CountSekeleton = styled.div`
  width: 50px;
  height: 30px;
  border-radius: 5px;
  background-color: rgb(247 247 247);
  margin-left: 10px;
`;

export const CountTextSekeleton = styled.span`
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: rgb(247 247 247);
  margin-left: 10px;
`;
