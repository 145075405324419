import React from 'react';
import { FlexWrap } from '@components/wrapper';
import * as Styled from './styled';

export default function ProductsSkeleton() {
  return (
    <FlexWrap className={'gap-x-9'}>
      {new Array(9).fill(null).map((x, i) => (
        <Styled.CardSkeleton key={i} />
      ))}
    </FlexWrap>
  );
}
