import dateFormat from 'dateformat';
import React from 'react';
import { useTranslation } from 'next-i18next';
import { noop } from 'lodash';
import { discountAmount } from '@lib/price';
import { Title } from '@components/common';
import { Card } from '@components/common/CardGrid';
import useExchange from '@hooks/useExchange';
import CardGroupSkeleton from '@components/common/CardGroup/CardGroupSkeleton';
import Grid from '@components/layout/Grid/Grid';
import { LanguageCode } from '../../../src/constants';
import { ProductCardsWithBrandQuery } from '../../../src/generated/graphql';
import { ICardClickProps } from '../Card/type';
import { CardGroupContainer, CardGroupItemContainer } from './styled';

type Props = {
  title: string;
  source: string;
  products: ProductCardsWithBrandQuery['productCards'];
  getProductViewUrl: (val: string) => string;
  isPromotionTimer?: boolean;
  showSeeMore?: boolean;
  isDisplayRankBadge?: boolean;
  handleCardClick?: (props: ICardClickProps, order: number) => void;
};

export default function CardGroup({
  title,
  source,
  products,
  getProductViewUrl,
  isPromotionTimer = false,
  isDisplayRankBadge = false,
  showSeeMore = true,
  handleCardClick = noop,
  ...rest
}: Props): React.ReactElement {
  const { i18n } = useTranslation();
  const { isReady } = useExchange(i18n.language as LanguageCode);
  if (!isReady) return <CardGroupSkeleton />;

  const convertProductCardType = (productInfo: ProductCardsWithBrandQuery['productCards'][0]) => {
    const goodsNo = productInfo.id.toString();
    return {
      ...productInfo,
      productViewUrl: getProductViewUrl(goodsNo),
      goodsNo,
      brandCd: productInfo.brand.code,
      brandName: productInfo.brand.name,
      title: productInfo.title,
      reviewCount: productInfo.reviewCount,
      viewCount: productInfo.viewCount,
      badgeName: productInfo.badgeNames.length > 0 ? productInfo.badgeNames[0] : '',
      languageCode: i18n.language as LanguageCode,
      price: productInfo.salePrice,
      realPrice: productInfo.price,
      discountPercentage: discountAmount(productInfo.price, productInfo.salePrice),
      subImageUrl: productInfo.subImageUrl,
      imageUrl: productInfo.mainImageUrl,
      isGoods: true,
      extensions: productInfo.extensions.map((extension) => extension),
      isPromotion: Boolean(productInfo.promotionEndDate),
      promotionEndDate: productInfo.promotionEndDate ? dateFormat(productInfo.promotionEndDate, 'yyyy-mm-dd HH:MM:ss') : productInfo.promotionEndDate,
    };
  };

  return (
    <CardGroupContainer {...rest}>
      <Grid.Item>
        <Title viewLinkUrl={source} isView={showSeeMore}>
          {title}
        </Title>
      </Grid.Item>
      <Grid.Item>
        <CardGroupItemContainer>
          {products.map((goodsInfo, idx) => {
            const cardType = convertProductCardType(goodsInfo);
            return (
              <Grid.Item key={idx} xl={3} lg={6} md={4} sm={4}>
                <Card
                  {...cardType}
                  ranking={isDisplayRankBadge && idx < 3 && idx + 1}
                  isPromotionTimer={isPromotionTimer}
                  onClick={(product) => {
                    handleCardClick(product, idx + 1);
                  }}
                />
              </Grid.Item>
            );
          })}
        </CardGroupItemContainer>
      </Grid.Item>
    </CardGroupContainer>
  );
}
