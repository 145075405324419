import React from 'react';
import * as Styled from './styled';

type Props = {
  className?: string;
  children: React.ReactElement;
  color: string;
};

export default function Banner({ className, color, children }: Props) {
  return (
    <Styled.ColorBannerContainer className={className} color={color}>
      {children}
    </Styled.ColorBannerContainer>
  );
}
