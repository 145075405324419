import styled from 'styled-components';
import { SlideBanner } from '@components/common';
import { ResponsiveSlideBanner } from '@components/common/ResponsiveSlideBanner/ResponsiveSlideBanner';
import { FlexColumn } from '@components/wrapper';

export const Wrapper = styled(FlexColumn)((props: any) => {
  return {
    gap: '48px',
    padding: '48px 0',
    [props.theme.breakpoints.up('lg')]: {
      // desktop
      gap: '80px',
    },
  };
});
export const SlideGroup = styled(SlideBanner)`
  margin-bottom: 90px;
  box-sizing: content-box;
`;
export const SlideResponsiveGroup = styled(ResponsiveSlideBanner)`
  box-sizing: content-box;
`;

export const HoverBannerGroup = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 100px;
  padding-top: 22px;
`;
